import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

// bootstrap
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';

import { I18nModule } from '../i18n/i18n.module';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { IconAssetComponent } from './icon-asset/icon-asset.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ToggleComponent } from './toggle/toggle.component';
import { SelectComponent } from './select/select.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { AddAmountModalComponent } from './add-amount-modal/add-amount-modal.component';
import { AgeFilterComponent } from './age-filter/age-filter.component';
import { StepsComponent } from './steps/steps.component';
import { MallchatCheckboxComponent } from './inputs/mallchat-checkbox/mallchat-checkbox.component';
import { MallchatRadioComponent } from './inputs/mallchat-radio/mallchat-radio.component';
import { SharedTransacionsViewComponent } from './shared-transacions-view/shared-transacions-view.component';
import { ViewFileModalComponent } from './view-file-modal/view-file-modal.component';
import { ExportCsvComponent } from './export-csv/export-csv.component';
import { ExportCsvModalComponent } from './export-csv-modal/export-csv-modal.component';
import { BackButtonDirective } from './directives/back-button.directive';
import { ImagePlaceholderComponent } from './image-placeholder/image-placeholder.component';
import {UserComponent} from "../users/user/user.component";
import {QRCodeModule} from "angularx-qrcode";
import { AddFeeModalComponent } from './add-fee-modal/add-fee-modal.component';
@NgModule({
  declarations: [
    UserComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    IconAssetComponent,
    PaginationComponent,
    ToggleComponent,
    SelectComponent,
    DatePickerComponent,
    ClickOutsideDirective,
    AddAmountModalComponent,
    AgeFilterComponent,
    StepsComponent,
    MallchatCheckboxComponent,
    MallchatRadioComponent,
    SharedTransacionsViewComponent,
    ViewFileModalComponent,
    ExportCsvComponent,
    ExportCsvModalComponent,
    BackButtonDirective,
    ImagePlaceholderComponent,
    AddFeeModalComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    I18nModule,
    QRCodeModule
  ],
    exports: [
        FooterComponent,
        HeaderComponent,
        SidebarComponent,
        PaginationComponent,
        ToggleComponent,
        SelectComponent,
        IconAssetComponent,
        DatePickerComponent,
        AddAmountModalComponent,
        AgeFilterComponent,
        StepsComponent,
        MallchatCheckboxComponent,
        MallchatRadioComponent,
        SharedTransacionsViewComponent,
        ViewFileModalComponent,
        ExportCsvComponent,
        BackButtonDirective,
        ImagePlaceholderComponent,
        UserComponent,
        AddFeeModalComponent

    ]
})
export class SharedModule { }
