import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import { fadeAnimation } from './app.animation';
import { AuthService } from '@auth/auth.service';
import {ConnectionService} from 'ng-connection-service';

@Component({
  selector: 'maalchat-crm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation],
})
export class AppComponent {

  isLoggedIn;

  constructor(private router: Router,
              private connectionService: ConnectionService,
              private authService: AuthService) {
    this.checkInternetConnection();

    authService.isLoggedIn
      .subscribe(value => {
        this.isLoggedIn = value;
      });
    // scroll to top on each route change
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => window.scrollTo(0, 0));
  }
  public checkInternetConnection() {
    this.connectionService.monitor().subscribe(isConnected => {
      if (isConnected){
        location.reload();
      }
    });
  }
}
