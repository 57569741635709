import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {AuthService} from '@auth/auth.service';

@Component({
  selector: 'maalchat-crm-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  currentUrl: string;
  public userType;

  constructor(public router: Router, private auth: AuthService) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
  }

  ngOnInit() {
    this.userType = this.auth.getUserType();
    this.currentUrl = this.currentUrl ? this.currentUrl : '/dashboard';

  }

}
