<div class="AgentEditProfilePage Page">
        <a routerLink="../" class="AgentEditProfilePage__BackLink" *ngIf="!isProfile">
          <i class="fas fa-chevron-left"></i>
          Back
        </a>
        <div class="AgentEditProfilePage__Header">
          Edit Profile
        </div>
        <div class="AgentEditProfilePage__FormWrapper "   >

          <div class="loader" *ngIf="showloader"> </div>

          <div class="FormWrapper__ImageLoader">
            <div class="ImageLoader__Title">
              Profile photo
              <button class="btn ImageLoader__BtnAddImage" (click)="fileUploader.click()">
                Add Image
              </button>
            </div>

            <div maalchatCrmDragDrop (files)="filesDropped($event)" class="ImageLoader__Preview">
              <img [src]="imagePreview" *ngIf="imagePreview" alt="Profile Photo" />
              <input type="file" (change)="onFileUploaderChange($event)" accept="image/*" #fileUploader>
            </div>
            <div class="ImageLoader__PreviewText">
              Or drop image to upload
            </div>
          </div>
          <form autocomplete="off" [formGroup]="form">
            <div class="FormWrapper__Info"  [ngClass]="{'FormWrapper-extend' :isChangePasswordClicked}"  >
              <div class="form-group">
                <label for="companyName">
                  Name
                </label>
                <input type="text" class="form-control" id="companyName" formControlName="companyName" />
                <div class="form-control-error" *ngIf="isFormSubmitted && form.controls.companyName.hasError('required')">
                  Name is required
                </div>
              </div>
              <div class="form-group flex-form-group">
                <div class="form-group with-margin-bottom">
                  <label for="email">
                    Email
                  </label>
                  <input type="email" class="form-control" id="email" value="someEmail@gmail.com" formControlName="email"
                    readonly />
                </div>
                <div class="form-group with-margin-bottom">
                  <label for="phoneNumber">
                    Phone number
                  </label>
                  <div>
<!--
                    <input type="text" class="form-control contrycode" id="contrycode" formControlName="contrycode" /> -->


                    <input type="number" class="form-control" id="phoneNumber" formControlName="phoneNumber" />

                  </div>
                  <div class="form-control-error" *ngIf="isFormSubmitted && form.controls.phoneNumber.hasError('required') ">
                  PhoneNumber is required
                  </div>
                </div>
              </div>


            </div>
            <div class="FormWrapper__Controls">
              <a *ngIf="!isProfile" routerLink="../" class="btn Controls__BtnCancel">
                Cancel
              </a>
              <button class="btn Controls__BtnSaveChanges" (click)="handleFormSubmission()">
                Save changes
              </button>
            </div>
          </form>
        </div>
      </div>
