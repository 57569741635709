import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateService } from '@ngx-translate/core';
import { AgmCoreModule } from '@agm/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// core module
import { CoreModule } from './core/core.module';
import {
  RequestInterceptor,
  TokenInterceptor,
  LanguageInterceptor
} from './core/interceptors';

// i18n module
import { I18nModule } from './i18n/i18n.module';
import { I18nService } from './i18n/services';

// shared module
import { SharedModule } from './shared/shared.module';

// env
import { environment } from '@environment';

// services
import {
  AgentsService,
  StationsService,
  TransactionsService,
  UploadService,
  UsersService,

} from '@services/all';

// auth
import {
  AuthGuardService,
  AuthService
} from '@auth/index';
import {ToastrModule} from 'ngx-toastr';
import { PopoverModule } from 'ngx-bootstrap/popover';
import {ConnectionServiceModule} from 'ng-connection-service';
import {EditPremiumAgentComponent} from "./premium-agents/edit-premium-agent/edit-premium-agent.component";










@NgModule({
  declarations: [
    AppComponent,    EditPremiumAgentComponent,

  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: `${environment.googleMapsKey}`
    }),
    AppRoutingModule,
    CoreModule,
    SharedModule,
    I18nModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 6000,
      positionClass: 'toast-bottom-right',
    }),
    PopoverModule.forRoot(),
    ConnectionServiceModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    I18nService,
    TranslateService,
    TokenInterceptor,
    LanguageInterceptor,
    AgentsService,
    StationsService,

    TransactionsService,
    UploadService,
    UsersService,
    AuthGuardService,
    AuthService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private translateService: TranslateService,
              private http: HttpClient,
              private i18nService: I18nService) {
    translateService.setDefaultLang('en');
    const selectedLanguage = this.i18nService.getUserLanguage();
    translateService.use(selectedLanguage ? selectedLanguage : 'en');
    this.translateService.onLangChange
      .subscribe((langOptions: any) => {
        const language = langOptions.lang;
        if (language === 'ar') {
          document.body.classList.add('WithArabic');
        } else {
          document.body.classList.remove('WithArabic');
        }

        // save selected language
        this.i18nService.setUserLanguage(language);
      });
  }
}
