/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/router";
import * as i6 from "ngx-bootstrap/dropdown";
import * as i7 from "ngx-bootstrap/component-loader";
import * as i8 from "@angular/animations";
import * as i9 from "./header.component";
import * as i10 from "../../../core/services/agents/index";
import * as i11 from "../../../core/authentication/auth.service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], [[8, "selected", 0]], null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.countryCode; _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit.countryCode; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.countryCode === _co.selectedCountry); _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_3); }); }
function View_HeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ul", [["class", "dropdown-menu UserDropdown"], ["id", "userDropdown"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "AdminDropdown": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 3, "li", [["role", "menuitem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [["class", "dropdown-item"], ["href", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "dropdown-menu UserDropdown"; var currVal_1 = _ck(_v, 3, 0, (_co.userType === "SYSTEM_USER")); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("HEADER.LOGOUT")); _ck(_v, 6, 0, currVal_2); }); }
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "nav", [["class", "navbar Header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "Header__Logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "a", [["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "MaalChat Logo"], ["src", "../../../../assets/img/header-logo.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 13, "div", [["class", "Header__Dropdowns"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "select", [["class", "cityselect"], ["id", "city"], ["style", "width: 100px; height:  35px; color: white; background-color: #202a36; border: none"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onCountrySelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(8, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 16777216, null, null, 9, "div", [["class", "Dropdowns__User"], ["container", "body"], ["dropdown", ""]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], null, null, null, null)), i1.ɵprd(512, null, i6.BsDropdownState, i6.BsDropdownState, []), i1.ɵdid(11, 212992, null, 0, i6.BsDropdownDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i7.ComponentLoaderFactory, i6.BsDropdownState, i6.BsDropdownConfig, i8.AnimationBuilder], { container: [0, "container"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 6, "a", [["aria-controls", "userDropdown"], ["dropdownToggle", ""]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 147456, null, 0, i6.BsDropdownToggleDirective, [i1.ChangeDetectorRef, i6.BsDropdownDirective, i1.ElementRef, i1.Renderer2, i6.BsDropdownState], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 0, "img", [["alt", "User Avatar"], ["src", "assets/img/Avatar.png"]], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, [" ", " "])), (_l()(), i1.ɵeld(16, 0, null, null, 0, "i", [["class", "fas fa-caret-down"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_3)), i1.ɵdid(18, 16384, null, 0, i6.BsDropdownMenuDirective, [i6.BsDropdownState, i1.ViewContainerRef, i1.TemplateRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/"; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.countiesList; _ck(_v, 8, 0, currVal_3); var currVal_7 = "body"; _ck(_v, 11, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵnov(_v, 11).dropup; var currVal_5 = i1.ɵnov(_v, 11).isOpen; var currVal_6 = (i1.ɵnov(_v, 11).isOpen && i1.ɵnov(_v, 11).isBs4); _ck(_v, 9, 0, currVal_4, currVal_5, currVal_6); var currVal_8 = true; var currVal_9 = i1.ɵnov(_v, 13).isDisabled; var currVal_10 = i1.ɵnov(_v, 13).isOpen; _ck(_v, 12, 0, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.user.userName; _ck(_v, 15, 0, currVal_11); }); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.router.url !== "/login"); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "maalchat-crm-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i9.HeaderComponent, [i4.TranslateService, i5.Router, i10.AgentsService, i11.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("maalchat-crm-header", i9.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
