import {Component, OnInit, NgModule} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBase} from 'src/app/utils/classes';
import {UsersService, UploadService, AgentsService} from '@services/all';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FileHandle} from 'src/app/core/Directives/dragDrop.directive';
import {FolderName} from '@models/folder-name.enum';
import {AgentCompanyProfileDetails} from '@models/agentCompanyProfileDetails.model';
import {PremiumAgentModel} from '@models/premiumAgent.model';

@Component({
  selector: 'maalchat-crm-edit-premium-agent',
  templateUrl: './edit-premium-agent.component.html',
  styleUrls: ['./edit-premium-agent.component.scss']
})
export class EditPremiumAgentComponent extends FormBase implements OnInit {

  form: FormGroup;
  isFormSubmitted: boolean;
  isFormProcessing = false;
  confirmPasswordRequired: boolean;
  public isProfile = false;

  currentPasswordRequired: boolean;
  contrycode = '';
  showloader = false;


  PagentId;
  fileReader: FileReader = new FileReader();
  imagePreview: string | ArrayBuffer;
  isChangePasswordClicked: boolean = false;
  changepasswordRequired = false;
  fileTouplaod: any;

  constructor(private activatedRoute: ActivatedRoute, private usersService: UsersService,
              private router: Router, private uploadservice: UploadService, private agentService: AgentsService,
              private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit() {

    this.form = this.formBuilder.group({
      companyName: [null, Validators.required],
      email: [null, Validators.required],
      phoneNumber: [null, Validators.required],

    });
    this.PagentId = +this.activatedRoute.snapshot.paramMap.get('PagentId');
    if (!this.PagentId) {
      this.getMineBusiness();
      this.isProfile = true;
    } else {
      this.setFromValues();
    }
    this.fileReader.onload = () => {
      this.imagePreview = this.fileReader.result;
    };
  }


  setFromValues() {
    this.showloader = true;
    this.agentService.getPremiumAgentProfile(this.PagentId).subscribe((res: PremiumAgentModel) => {
      this.form.setValue({
        companyName: (res.name != undefined) ? res.name : null,
        phoneNumber: (res.contactInformation != undefined) ? res.contactInformation.phoneNumber : null,
        email: res.adminEmail,
      });
      this.imagePreview = (res.logoImageUrl != undefined) ? res.logoImageUrl.toString() : '';
      this.showloader = false;
    }, err => {
      this.showloader = false;
    })
  }

  getMineBusiness() {
    this.showloader = true;
    this.agentService.getMineProfile().subscribe((res: PremiumAgentModel) => {
      this.PagentId = res.id;
      this.form.setValue({
        companyName: (res.name != undefined) ? res.name : null,
        phoneNumber: (res.contactInformation != undefined) ? res.contactInformation.phoneNumber : null,
        email: res.adminEmail,
      });
      this.imagePreview = (res.logoImageUrl != undefined) ? res.logoImageUrl.toString() : '';
      this.showloader = false;
    }, err => {
      this.showloader = false;
    })
  }


  filesDropped(files: FileHandle[]): void {

    if (files && files[0]) {
      this.fileReader.readAsDataURL(files[0].file);
      this.fileTouplaod = files[0].file;
    }
  }

  onFileUploaderChange(event) {

    const input = event.target;
    if (input.files && input.files[0]) {
      this.fileReader.readAsDataURL(input.files[0]);
      this.fileTouplaod = input.files[0];
    }
  }

  get f() {
    return this.form.controls;
  }

  initForm() {
  }


  toggleChangePassword() {

    this.isChangePasswordClicked = !this.isChangePasswordClicked;
    this.changepasswordRequired = !this.changepasswordRequired;

  }


  handleFormSubmission() {
    this.isFormSubmitted = true;
    this.isFormProcessing = true;
    if (this.fileTouplaod) {
      this.showloader = true;
      this.uploadservice.upload(FolderName.premiumAgentLogo, this.fileTouplaod).then(res => {
        this.imagePreview = res.fileUrl;
        this.showloader = false;
        this.updateinfo();
      }).catch((err: any) => {
        this.showloader = false;
      })
    } else {
      this.updateinfo();
    }

  }

  updateinfo() {

    let para = {
      companyName: this.form.value.companyName,
      contactPhone: this.form.value.phoneNumber,
      logoImageUrl: this.imagePreview,
    };
    if (!this.form.valid) {
      return;
    }
    this.showloader = true;
    this.agentService.premiumAgentUpdate(para, this.PagentId).subscribe(res => {

      this.isFormProcessing = false;
      this.isFormSubmitted = false;
      this.showloader = false;
      if (this.isProfile) {
        this.router.navigateByUrl('agents');
      } else {
        this.router.navigateByUrl('premiumagents/' + this.PagentId)
      }
    }, err => {
      console.error(err);
      this.isFormProcessing = false;
      this.showloader = false;
    })
  }


}
