import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
export class StationsService {
    constructor(http, router) {
        this.http = http;
        this.router = router;
    }
    searchStations(searchKey, page, size, filter, srt, srtd, city, company) {
        // let  url = environment.endPoint + 'crm/top-ups/browsing/listing?page=' + page + '&len=' + size ;
        let url = environment.endPoint + 'crm/top-ups/browsing/listing?page=' + page + '&len=' + size + '&searchKey=' + searchKey + '&filter=' + filter + '&srt=' + srt + '&srtd=' + srtd + '';
        if (city != 'all' && city != undefined && city != null) {
            url = url + '&city=' + city;
        }
        console.log("company " + company);
        if (company != 'all' && company != undefined && company != null) {
            url = url + '&company=' + company;
        }
        return this.getListByUrl(url);
    }
    statusChange(stationId) {
        return this.http.put(environment.endPoint + '/crm/top-ups/' + stationId + '/status', null);
    }
    getListByUrl(url) {
        try {
            return this.http.get(url);
        }
        catch (err) {
            this.isUnauthorized(err);
            return Observable.throw(err);
        }
    }
    getCompanies() {
        return this.http.get(environment.endPoint + 'crm/agent-companies/browsing/names');
    }
    isUnauthorized(err) {
        if (err.status === 401) {
            this.router.navigateByUrl('/login');
        }
    }
}
StationsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StationsService_Factory() { return new StationsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router)); }, token: StationsService, providedIn: "root" });
