import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
export class UsersService {
    constructor(http, router) {
        this.http = http;
        this.router = router;
        this.USERS_APIS = {
            allUsers: 'crm/mobileusers',
            status: 'crm/mobileusers/total-stats',
            applyChange: 'crm/mobileuser/id/action',
            getUserProfile: 'crm/mobileuser/id',
            updateUserIdURL: 'crm/mobileuser/id',
            updateUser: 'crm/mobileuser/profile/id'
        };
        this.host = environment.endPoint;
    }
    getUsers(params) {
        let urlParams = '';
        const size = 'len=7';
        Object.keys(params).forEach((key) => {
            urlParams = urlParams + '&' + key + '=' + params[key];
        });
        return this.http.get(this.host + this.USERS_APIS.allUsers + '?' + size + urlParams);
    }
    getStatus(par) {
        const params = JSON.parse(JSON.stringify(par));
        delete params.page;
        delete params.srt;
        delete params.srtd;
        let urlParams = '';
        Object.keys(params).forEach((key, i) => {
            if (i === 0) {
                urlParams = '?' + key + '=' + params[key];
            }
            else {
                urlParams = urlParams + '&' + key + '=' + params[key];
            }
        });
        return this.http.get(this.host + this.USERS_APIS.status + urlParams);
    }
    applyAction(id, action) {
        return this.http.put(this.host + this.USERS_APIS.applyChange.replace('id', id).replace('action', action), {});
    }
    getUserProfile(id) {
        return this.http.get(this.host + this.USERS_APIS.getUserProfile.replace('id', id));
    }
    updateUserIdURL(id, body) {
        return this.http.put(this.host + this.USERS_APIS.updateUserIdURL.replace('id', id), body);
    }
    updateUserInfo(user, userId, nationalId) {
        const body = {
            firstName: user.firstName,
            secondName: user.secondName,
            thirdName: user.thirdName,
            lastName: user.lastName,
        };
        if (nationalId !== user.nationalId) {
            body.nationalId = user.nationalId;
        }
        return this.http.put(this.host + this.USERS_APIS.updateUser.replace('id', userId), body);
    }
    forgotPassword(email) {
        const requestBody = {
            emailAddress: email,
            targetPageUrl: `${window.location.origin}/reset-password`,
        };
        return this.http.post(environment.endPoint + UsersService.FORGOT_PASSWORD_URL, requestBody);
    }
    resetPassword(param) {
        return this.http.post(environment.endPoint + UsersService.RESET_PASSWORD_URL, param);
    }
}
UsersService.FORGOT_PASSWORD_URL = '/crm/users/auth/forgot-password';
UsersService.RESET_PASSWORD_URL = '/crm/users/auth/reset-password';
UsersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsersService_Factory() { return new UsersService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router)); }, token: UsersService, providedIn: "root" });
