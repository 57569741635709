import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';

import {TokenInterceptor} from './token.interceptor';
import {LanguageInterceptor} from './language.interceptor';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '@auth/auth.service';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(private tokenInterceptor: TokenInterceptor,
              private toastr: ToastrService,
              private languageInterceptor: LanguageInterceptor,
              private router: Router, private auth: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.headers.has('Content-Type')) {
      request = request.clone({headers: request.headers.set('Content-Type', 'application/json')});
    }
    request = this.tokenInterceptor.addToken(request);
    request = this.languageInterceptor.addLanguage(request);


    return next.handle(request).pipe(
      // retry(2),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = error && error.error ?  error.error.error : error.name;
        errorMessage = navigator.onLine ? errorMessage : 'No Internet Connection';

        this.toastr.error(errorMessage);
        if (error.status === 401) {
          console.log(error);
          localStorage.removeItem(AuthService.USER_ID);
          localStorage.removeItem(AuthService.TOKEN_NAME);
          localStorage.removeItem(AuthService.TOKEN_USERNAME);
          this.router.navigate(['/login']);
          location.reload();
          return throwError(error);
        }
        return throwError(error);
      })
    );
  }
}
