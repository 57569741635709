import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
export class TransactionsService {
    constructor(http, router) {
        this.http = http;
        this.router = router;
        this.TRAN_APIS = {
            LIST_ALL_TRANSACTIONS: '/crm/transactions',
            TRANSACTION_TOTALS: 'crm/transactions/total-stats',
            USER_TRANSACTION: 'crm/transactions/user/id',
            USER_TRANSACTION_TOTAL: 'crm/transactions/user/id/total-stats'
        };
        this.host = environment.endPoint;
    }
    getTransaction(userId) {
        return this.http.get(environment.endPoint + 'crm/management/users/' + userId + '/details');
    }
    getAllTransaction(params) {
        let urlParams = '';
        const size = 'len=5';
        Object.keys(params).forEach((key) => {
            urlParams = urlParams + '&' + key + '=' + params[key];
        });
        return this.http.get(this.host + this.TRAN_APIS.LIST_ALL_TRANSACTIONS + '?' + size + urlParams);
    }
    getTransactionTotals(params) {
        let urlParams = '';
        const size = 'len=5';
        Object.keys(params).forEach((key) => {
            urlParams = urlParams + '&' + key + '=' + params[key];
        });
        return this.http.get(this.host + this.TRAN_APIS.TRANSACTION_TOTALS + '?' + size + urlParams);
    }
    getUserTransactionTotals(params, id) {
        let urlParams = '';
        const size = 'len=5';
        Object.keys(params).forEach((key) => {
            urlParams = urlParams + '&' + key + '=' + params[key];
        });
        return this.http.get(this.host + this.TRAN_APIS.USER_TRANSACTION_TOTAL.replace('id', id) + '?' + size + urlParams);
    }
    getUserTransactions(params, userid) {
        let urlParams = '';
        const size = 'len=5';
        Object.keys(params).forEach((key) => {
            urlParams = urlParams + '&' + key + '=' + params[key];
        });
        return this.http.get(this.host + this.TRAN_APIS.USER_TRANSACTION.replace('id', userid) + '?' + size + urlParams);
    }
    SearchState(callerId, name, params) {
        let urlParams = '';
        const size = 'len=5';
        Object.keys(params).forEach((key) => {
            urlParams = urlParams + '&' + key + '=' + params[key];
        });
        const url = environment.endPoint + `crm/transactions/${name}/${callerId}/total-stats` + '?' + size + urlParams;
        return this.getDataListByUrl(url);
    }
    SearchTransactionsforCallers(callerName, callerId, params) {
        let urlParams = '';
        const size = 'len=5';
        Object.keys(params).forEach((key) => {
            urlParams = urlParams + '&' + key + '=' + params[key];
        });
        let url = environment.endPoint + `crm/transactions/${callerName}/${callerId}` + '?' + size + urlParams;
        return this.getDataListByUrl(url);
    }
    filterForTrans(searchKey, srt, direction, from, to) {
        let url = '';
        if (searchKey != null && searchKey != '') {
            url = url.concat('', '&searchKey=' + searchKey);
        }
        if (srt != null && srt != '') {
            url = url.concat('&srt=' + srt);
        }
        if (direction != null && direction != 'all') {
            url = url.concat('&direction=' + direction);
        }
        if (from != null && from != undefined) {
            url = url + '&from=' + from;
        }
        if (to != null && to != undefined) {
            url = url + '&to=' + to;
        }
        return url;
    }
    searchAllTransactionsbyCaller(searchKey, page, size, srt, srtd, from, to, callerName, callerId) {
        let url = environment.endPoint + 'crm/transactions/?page=' + page + '&len=' + size +
            '&searchKey=' + searchKey +
            '&srt=' + srt +
            '&srtd=' + srtd;
        if (from != null && from != undefined) {
            url = url + '&from=' + from;
        }
        if (to != null && to != undefined) {
            url = url + '&to=' + to;
        }
        console.log(url);
        return this.getDataListByUrl(url);
    }
    getTransactionbyId(tranId) {
        let url = `${environment.endPoint}crm/transaction/${tranId}`;
        return this.http.get(url);
    }
    getDataListByUrl(url) {
        try {
            return this.http.get(url);
        }
        catch (err) {
            this.isUnauthorized(err);
            return Observable.throw(err);
        }
    }
    isUnauthorized(err) {
        if (err.status === 401) {
            this.router.navigateByUrl('/login');
        }
    }
}
TransactionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TransactionsService_Factory() { return new TransactionsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router)); }, token: TransactionsService, providedIn: "root" });
