import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, of } from 'rxjs';
import { environment } from '@environment';
import { getUserDevice } from '@helpers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AuthService {
    constructor(http) {
        this.http = http;
        this.isLoggedIn = new BehaviorSubject(false);
        this.isLoggedIn.next(!!this.getToken());
    }
    getToken() {
        return localStorage.getItem(AuthService.TOKEN_NAME);
    }
    getUserId() {
        return localStorage.getItem(AuthService.USER_ID);
    }
    getUserType() {
        return localStorage.getItem(AuthService.USER_TYPE);
    }
    getUserName() {
        return localStorage.getItem(AuthService.USER_NAME);
    }
    setToken(token) {
        localStorage.setItem(AuthService.TOKEN_NAME, token);
        this.isLoggedIn.next(true);
    }
    setUserName(userName) {
        localStorage.setItem(AuthService.USER_NAME, userName);
    }
    setUserId(userId) {
        localStorage.setItem(AuthService.USER_ID, String(userId));
    }
    setUserType(uType) {
        localStorage.setItem(AuthService.USER_TYPE, String(uType));
    }
    removeLocalStorageItems() {
        localStorage.removeItem(AuthService.USER_ID);
        localStorage.removeItem(AuthService.TOKEN_NAME);
        this.isLoggedIn.next(false);
        return of(true);
    }
    login(loginCredentials) {
        const requestBody = Object.assign({}, loginCredentials, getUserDevice());
        let headers = new HttpHeaders();
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Accept-Language', 'en-US');
        return this.http.post(environment.endPoint + AuthService.LOGIN_URL, requestBody, { headers });
    }
    logout() {
        this.http.delete(environment.endPoint + AuthService.LOGOUT_URL).subscribe(a => {
        });
        return this.removeLocalStorageItems();
    }
}
AuthService.LOGIN_URL = 'crm/users/auth/login';
AuthService.TOKEN_NAME = 'MAALCHAT_TOKEN';
AuthService.USER_ID = 'MAALCHAT_USER_ID';
AuthService.USER_TYPE = 'MAALCHAT_USER_TYPE';
AuthService.USER_NAME = 'USER_NAME';
AuthService.LOGOUT_URL = 'crm/users/auth/logout';
AuthService.TOKEN_USERNAME = 'MAALCHAT_USERNAME';
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthService, providedIn: "root" });
