import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '@auth/auth.service';
import {AgentsService} from "@services/all";

@Component({
  selector: 'maalchat-crm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  user = {
    name: 'Admin',
    userName: this.authService.getUserName(),
    id: this.authService.getUserId(),
    avatar: 'https://www.standict.eu/sites/all/themes/dotte/img/default_avatar.png'
  };
  selectedLanguage: string;
  public userType;
  public countiesList = [];
  public selectedCountry;
  public crmCurrency;


  constructor(private translateService: TranslateService,
              public router: Router,
              public agentsService: AgentsService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.userType = this.authService.getUserType();
    this.selectedLanguage = this.translateService.currentLang;
    // this.user.userName =  this.authService.getUserName();
    // this.countiesList = this.agentsService.citiesList;
    this.getCountries();
    this.selectedCountry = localStorage.getItem('countryCode');
  }
  public getCountries() {
    this.agentsService.getCountriesList().subscribe((res: any) => {
      this.countiesList = res;
      if(!this.selectedCountry)
      {
        this.selectedCountry = res[0];
        localStorage.setItem('countryCode', res[0].countryCode);
      }
    }, error => {
      console.log(error);
    });
  }

  onLanguageClick(event: Event, language: string) {
    event.preventDefault();
    this.translateService.use(language);
    this.selectedLanguage = language;
  }

  logout(event) {
    event.preventDefault();
    this.authService.logout()
      .subscribe(() => {
        this.router.navigate(['/login']);
      });
  }

  public onCountrySelected(value) {
    const country = this.countiesList.filter((item) => item.countryCode == value.target.value );
    localStorage.setItem('countryCode', value.target.value);
    localStorage.setItem('currency', country[0].currency.symbol);
    this.router.navigate(['/dashboard']);
    setTimeout(() => {
      location.reload();
    }, 1000);
  }
}
