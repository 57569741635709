import { Component, OnInit, Input } from '@angular/core';


export enum AssetType {
  SVG = 'svg',
  PNG = 'png',
}
@Component({
  selector: 'maalchat-crm-icon-asset',
  template: `
    <img [src]="'../../../assets/img/' + name + extension"
         [ngStyle]="styles"
         (error)="onImageError()"
    />
  `,
  styles: [`
    :host {
        display: inline-block;
    }
  `]
})
export class IconAssetComponent implements OnInit {

  @Input() type: AssetType = AssetType.SVG;
  @Input() width: number;
  @Input() height: number;
  @Input() name: string;
  @Input() alt: string;
  styles = {};
  extension: string;

  constructor() { }

  ngOnInit() {
    this.extension = this.type === AssetType.SVG ? '.svg' : '.png';
    console.log(this.extension)
    this.generateStyles();
  }

  generateStyles() {
    this.styles = {
      width: this.width ? this.width + 'px' : null,
      height: this.height ? this.height + 'px' : null,
    };
  }

  onImageError() {
    console.error(`${this.name}.svg does not exist`);
    throw Error(`${this.name}.svg does not exist`);
  }

}
