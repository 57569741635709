import * as i0 from "@angular/core";
export class I18nService {
    constructor() {
        this.storage = window.localStorage;
    }
    setUserLanguage(language) {
        this.storage.setItem(I18nService.LOCAL_STORAGE_KEY, language);
    }
    getUserLanguage() {
        return this.storage.getItem(I18nService.LOCAL_STORAGE_KEY);
    }
}
I18nService.LOCAL_STORAGE_KEY = 'MAALCHAT_CRM_LANGUAGE';
I18nService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function I18nService_Factory() { return new I18nService(); }, token: I18nService, providedIn: "root" });
