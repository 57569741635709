<nav class="navbar Header"  *ngIf="router.url !== '/login'"  >
  <div class="Header__Logo">
    <a routerLink="/">
      <img src="../../../../assets/img/header-logo.svg"
           alt="MaalChat Logo"/>
    </a>
  </div>
  <div class="Header__Dropdowns">
<!--    <div class="Dropdowns__LanguageSelector"-->
<!--         container="body"-->
<!--         dropdown>-->
<!--      <a dropdownToggle-->
<!--         aria-controls="languageDropdown">-->
<!--        {{ selectedLanguage }}-->
<!--        <i class="fas fa-caret-down"></i>-->
<!--        <ul id="languageDropdown"-->
<!--            class="dropdown-menu LanguageDropdown"-->
<!--            *dropdownMenu>-->
<!--          <li role="menuitem">-->
<!--            <a class="dropdown-item"-->
<!--               (click)="onLanguageClick($event, 'en')"-->
<!--               [ngClass]="{'Selected': selectedLanguage === 'en'}"-->
<!--               href="#">-->
<!--              {{'HEADER.ENGLISH' | translate}}-->
<!--            </a>-->
<!--          </li>-->
<!--          <li role="menuitem">-->
<!--            <a class="dropdown-item"-->
<!--               (click)="onLanguageClick($event, 'ar')"-->
<!--               [ngClass]="{'Selected': selectedLanguage === 'ar'}"-->
<!--               href="#">-->
<!--              {{'HEADER.ARABIC' | translate}}-->
<!--            </a>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </a>-->
<!--    </div>-->
    <select  style="width: 100px; height:  35px; color: white; background-color: #202a36; border: none" (change)="onCountrySelected($event)" id="city"
            class="cityselect">
      <option [selected]="option.countryCode === selectedCountry" *ngFor="let option of countiesList ; let i = index" [value]="option.countryCode">
        {{option.name}}
      </option>
    </select>
    <div class="Dropdowns__User"
         container="body"
         dropdown>
      <a dropdownToggle
         aria-controls="userDropdown">
        <img src="assets/img/Avatar.png"
             alt="User Avatar"/>
        {{ user.userName }}
        <i class="fas fa-caret-down"></i>
        <ul id="userDropdown"
            class="dropdown-menu UserDropdown"
            [ngClass]="{'AdminDropdown': userType === 'SYSTEM_USER'}"
            *dropdownMenu>
<!--          <li role="menuitem">-->
<!--            <a class="dropdown-item" routerLink="profile">-->
<!--             Settings-->
<!--            </a>-->
<!--          </li>-->
          <li role="menuitem">
            <a class="dropdown-item"
               href=""
               (click)="logout($event)">
              {{'HEADER.LOGOUT' | translate}}
            </a>
          </li>
        </ul>
      </a>
    </div>
  </div>
</nav>
