/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./icon-asset.component";
var styles_IconAssetComponent = ["[_nghost-%COMP%] {\n        display: inline-block;\n    }"];
var RenderType_IconAssetComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_IconAssetComponent, data: {} });
export { RenderType_IconAssetComponent as RenderType_IconAssetComponent };
export function View_IconAssetComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "img", [], [[8, "src", 4]], [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.onImageError() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.styles; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("../../../assets/img/" + _co.name) + _co.extension); _ck(_v, 0, 0, currVal_0); }); }
export function View_IconAssetComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "maalchat-crm-icon-asset", [], null, null, null, View_IconAssetComponent_0, RenderType_IconAssetComponent)), i0.ɵdid(1, 114688, null, 0, i2.IconAssetComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IconAssetComponentNgFactory = i0.ɵccf("maalchat-crm-icon-asset", i2.IconAssetComponent, View_IconAssetComponent_Host_0, { type: "type", width: "width", height: "height", name: "name", alt: "alt" }, {}, []);
export { IconAssetComponentNgFactory as IconAssetComponentNgFactory };
