/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared/layout/header/header.component.ngfactory";
import * as i3 from "./shared/layout/header/header.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/router";
import * as i6 from "./core/services/agents/index";
import * as i7 from "./core/authentication/auth.service";
import * as i8 from "./shared/layout/sidebar/sidebar.component.ngfactory";
import * as i9 from "./shared/layout/sidebar/sidebar.component";
import * as i10 from "./shared/layout/footer/footer.component.ngfactory";
import * as i11 from "./shared/layout/footer/footer.component";
import * as i12 from "@angular/common";
import * as i13 from "./app.component";
import * as i14 from "ng-connection-service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: { "animation": [{ type: 7, name: "routeAnimations", definitions: [], options: {} }] } });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "maalchat-crm-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.TranslateService, i5.Router, i6.AgentsService, i7.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "maalchat-crm-sidebar", [], null, null, null, i8.View_SidebarComponent_0, i8.RenderType_SidebarComponent)), i1.ɵdid(1, 114688, null, 0, i9.SidebarComponent, [i5.Router, i7.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "maalchat-crm-footer", [], null, null, null, i10.View_FooterComponent_0, i10.RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i11.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], [[24, "@routeAnimations", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(2, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(4, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, [["o", 4]], 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(8, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isLoggedIn; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.isLoggedIn; _ck(_v, 4, 0, currVal_2); _ck(_v, 6, 0); var currVal_3 = !_co.isLoggedIn; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 6).isActivated ? i1.ɵnov(_v, 6).activatedRoute : ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "maalchat-crm-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i13.AppComponent, [i5.Router, i14.ConnectionService, i7.AuthService], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("maalchat-crm-root", i13.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
