import { AuthService } from '@auth/auth.service';
import { environment } from '@environment';
import { HttpHeaders, HttpClient, HttpRequest } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import * as i0 from "@angular/core";
import * as i1 from "../../authentication/auth.service";
import * as i2 from "ngx-toastr";
import * as i3 from "@angular/common/http";
export class UploadService {
    constructor(authService, toastr, http) {
        this.authService = authService;
        this.toastr = toastr;
        this.http = http;
    }
    /**
     * usage
     * upload().subscribe
     * .subscribe(
     * event => {
     *       if (event.type == HttpEventType.UploadProgress) {
     *         const percentDone = Math.round(100 * event.loaded / event.total);
     *         console.log(`File is ${percentDone}% loaded.`);
     *       } else if (event instanceof HttpResponse) {
     *         console.log('File is completely loaded!');
     *       }
     *     },
     * (err) => {
     *        console.log("Upload Error:", err);
     *    }, () => {
     *       console.log("Upload done");
     *     }
     * )
     * @param folderName: UploadFolderName
     * @param file: File
     * return Observable<HttpEvent<any>>
     * @param vouchersCSV
     */
    upload(folderName, file, vouchersCSV = null) {
        const url = vouchersCSV ? `${environment.endPoint}crm/vouchers/items/${vouchersCSV.brandId}/${vouchersCSV.voucherId}/csv` : `${environment.endPoint}crm/files/${folderName}/upload`;
        const token = this.authService.getToken();
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            const xhr = new XMLHttpRequest();
            formData.append('file', file, file.name);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve(JSON.parse(xhr.response));
                    }
                    else {
                        reject(xhr.response);
                    }
                }
            };
            xhr.onerror = (err) => {
                console.error(err);
                if (!navigator.onLine) {
                    this.toastr.error('No Internet Connection');
                }
                reject(err);
            };
            xhr.open('POST', url, true);
            xhr.withCredentials = false;
            xhr.setRequestHeader('Authorization-Token', token);
            xhr.send(formData);
        });
    }
    UplaodFile(folderName, file) {
        const token = this.authService.getToken();
        console.log("-----");
        const formData = new FormData();
        formData.append('fileName', file.name);
        formData.append('file', file);
        // formData.forEach( x => {console.log(x)})
        const url = `${environment.endPoint}crm/files/${folderName}/upload`;
        const req = new HttpRequest('POST', url, formData, {
            headers: new HttpHeaders({ 'Authorization-Token': token })
        });
        return this.http.request(req);
        // this.http.post(url, formData,specialToken).toPromise;
        // // );
        // const req = new HttpRequest('POST', url, formData, {
        //   headers: new HttpHeaders({ 'Authorization-Token': token }), reportProgress: true,
        // });
        // return this.http.request(req);
        // const specialToken = {
        //   headers: new HttpHeaders({ 'Authorization-Token': token })
        // };
        // const url = `${environment.endPoint}crm/files/${folderName}/upload`;
        // return this.http.post(url, formData, specialToken).toPromise().catch((err: Response | any) => {
        //   console.log(err)
        // });
    }
}
UploadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UploadService_Factory() { return new UploadService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.ToastrService), i0.ɵɵinject(i3.HttpClient)); }, token: UploadService, providedIn: "root" });
